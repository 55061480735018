import IntroParagraph from "../../../src/components/introParagraph";
import SpaceBreakdown from "../../../src/components/spaceBreakdown";
import ArticleSpacer from "../../../src/components/articleSpacer";
import ImageRow from "../../../src/components/imageRow";
import ContentWrapper from "../../../src/components/contentWrapper";
import Reviews from "../../../src/components/reviewsRow";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  IntroParagraph,
  SpaceBreakdown,
  ArticleSpacer,
  ImageRow,
  ContentWrapper,
  Reviews,
  React,
  MDXTag
};